<template>

	<div class="enterprise" style="width: 100%;">
	<TableList :columns="columns" :get_table_list="this.get_table_list" :slot_table_list="['op','icon']"
		ref="list">
		<template slot="left_btn">
			<div style="display: flex;width: 100%;">
					
						<div id="" style="flex: 1;" class="search_form">
						</div>
						<a-button type="primary" class="mr12 " @click="go_edit">批量编辑</a-button>
			</div>
		</template>
		<template #op="{record}">
			<div class="op">
				<span @click="merge_open(record)" class="check">编辑</span>
			</div>
		</template>
	</TableList>
		<a-modal v-model:visible="mergeVisible" title="提示" @ok="mergeOk">
			<a-form :label-col="labelColEdit" :wrapper-col="wrapperColEdit">
				<a-form-item label="工号">
					<a-input v-model:value="drp_code" />
				</a-form-item>

			</a-form>
		</a-modal>
	
	</div>
</template>

<script>
		import TableList from "@/components/TableList";
	import {
		saveUserCode,
		getNoneCodeUserList,
		getBatchNoneCodeUser,
		batchSaveUserCode
	} from '@/api/enterprise.js'
	import {
		message,
		TreeDataItem,
		TreeDragEvent,
		DropEvent,
		Modal
	} from 'ant-design-vue';

	export default {
		components: {
			TableList
		},
		data() {
			return {
				all_list: [],
				editType: 'add',
				company_id: '',
				company_name: '',
				merge_company: '',
				addressLitst: [],
				search_form: false,
				visible: false,
				get_table_list: getNoneCodeUserList,
				columns: [{
						title: '姓名',
						dataIndex: 'username',
						key: 'username',
					},
					{
						title: '部门',
						dataIndex: 'department_name',
						key: 'department_name',
					},
					{
						title: '岗位',
						align: 'center',
						dataIndex: 'role_name',
						key: 'role_name',
					},
					{
						title: '状态',
						dataIndex: 'nature_status',
						align: 'center',
						key: 'nature_status',

					},
					{
						title: '入职日期',
						dataIndex: 'entry_time',
						key: 'entry_time',
						align: 'center',
					},
					{
						title: '工作性质',
						dataIndex: 'online_num',
						key: 'online_num',
					},
					{
						title: '工号',
						dataIndex: 'drp_code',
						key: 'drp_code',
					},
					{
						title: '操作',
						dataIndex: 'op',
						key: 'op',
						scopedSlots: {
							customRender: 'op'
						},
					},
				],
				delVisible: false,
				editVisible: false,
				mergeVisible: false,
				delCompany: false,

				labelColEdit: {
					span: 4
				},
				wrapperColEdit: {
					span: 20
				},
				wrapperCol: {
					span: 12
				},
				formState2: {},
				select_list: [],

				drp_code: '',
				uid: ''
			}

		},
		created() {
			this.getIndustry()
		},
		methods: {
			merge_open(item) {
				this.uid = item.uid
				this.mergeVisible = true
			},
			handleOk() {
				batchSaveUserCode({
					data: {
						batch_value: this.all_list
					}
				}).then(res => {
					this.visible = false
					this.getIndustry()
				})
			},
			mergeOk() {
				saveUserCode({
					data: {
						uid: this.uid,
						drp_code: this.drp_code
					}
				}).then(res => {
					this.mergeVisible = false
					this.uid = ''
					this.drp_code = ''
					this.$refs.list.get_list()
				})
			},

			getIndustry() {
				getBatchNoneCodeUser().then(res => {
					this.all_list = res.list
				})
			},
			go_edit(){
					this.$router.push({
						path: '/PersonnelRules/NoneCodeUserEdit'
					})
			},
			handle_submit() {

			}
		},

	}
</script>

<style lang="less" scoped>
	@import url("../../assets/less/app.less");
	@import 'info.less';

	.table_list_body {
		margin: 0;
		padding: 0;
	}

	.check {
		color: @cq_color;
		cursor: pointer;
	}

	.del {
		color: @cq_error;
	}

	.FieldSettingsBox {
		width: 100%;
	}

	.FieldSettingsBoxTitle {
		display: flex;
		width: 100%;
		padding: 8px 12px;
		border-bottom: 1px solid @cq_line_e;

		span:nth-child(1) {
			flex: .3;
			margin-left: 8px;
		}

		span:nth-child(2),
		span:nth-child(3),
		span:nth-child(4) {
			flex: 1;
		}

		span:nth-child(5),
		span:nth-child(6),
		span:nth-child(7) {
			flex: .8;
		}



	}

	.w100 {
		width: 100px;
	}

	/deep/.full-modal {
		/deep/.ant-modal {
			max-width: 100%;
			top: 0;
			padding-bottom: 0;
			margin: 0;
		}

		/deep/.ant-modal-content {
			display: flex;
			flex-direction: column;
			height: calc(90vh);
		}

		/deep/.ant-modal-body {
			flex: 1;
		}
	}
	.table_list_body{
		width: 100%;
	}
</style>
