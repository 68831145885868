import req from "../methods/req"
import method from '../methods/method'
// 合同公司列表
// export const getContractCompanyList = (data) => req({ url: '/manage/company.index/getContractCompanyList',...data })
// export const getContractCompanyDetail = (data) => req({ url: '/manage/company.index/getContractCompanyDetail',...data })

export const saveContractCompany = (data) => req({ url: '/manage/company.index/saveContractCompany',...data })
export const mergeContractCompany = (data) => req({ url: '/manage/company.index/mergeContractCompany',...data })

export const delContractCompany = (data) => req({ url: '/manage/company.index/delContractCompany',...data })
// export const getContractCompanySelect = (data) => req({ url: '/manage/company.index/getContractCompanySelect',...data })
export const editContractCompany = (data) => req({ url: '/manage/company.index/editContractCompany',...data })


export const getOrganizationInfo = (data) => req({ url: '/manage/company.organization_setting/getOrganizationInfo',...data })

export const getContractCompanyList = function (data){
    return method.get_list({
        url:'/manage/company.index/getContractCompanyList',
        ...data
    })
}

export const getContractCompanyDetail = function (data){
    return req({
        url:'/manage/company.index/getContractCompanyDetail',
        ...data
    })
}
export const getContractCompanySelect = function (data){
    return req({
        url:'/manage/company.index/getContractCompanySelect',
        ...data
    })
}
export const getAllUser = (data) => req({ url: '/manage/crm.common/getAllUser',...data })
// 通讯录设置
// 获取通讯录管理信息
export const getManageInfo = (data) => req({ url: '/manage/company.address_book_manage/getManageInfo',...data })
// 获取通讯录可展示字段
export const getBookShowField = (data) => req({ url: '/manage/company.address_book_manage/getBookShowField',...data })
export const bookFieldForbid = (data) => req({ url: '/manage/company.address_book_manage/bookFieldForbid',...data })
export const bookFieldStart = (data) => req({ url: '/manage/company.address_book_manage/bookFieldStart',...data })
export const bookFieldSort = (data) => req({ url: '/manage/company.address_book_manage/bookFieldSort',...data })
export const setViewBookEmployees = (data) => req({ url: '/manage/company.address_book_manage/setViewBookEmployees',...data })
export const setBookViewRange = (data) => req({ url: '/manage/company.address_book_manage/setBookViewRange',...data })
export const getViewBookSelect = (data) => req({ url: '/manage/company.address_book_manage/getViewBookSelect',...data })
export const getBookRangeSelect = (data) => req({ url: '/manage/company.address_book_manage/getBookRangeSelect',...data })

//人事档案设置
export const getEmployeeAttributesTitle = (data) => req({ url: '/manage/company.personnel_file/getEmployeeAttributesTitle',...data })
export const getEmployeeAttributesList = (data) => req({ url: '/manage/company.personnel_file/getEmployeeAttributesList',...data })
export const getFieldNameType = (data) => req({ url: '/manage/company.personnel_file/getFieldNameType',...data })
export const getCanAddFieldType = (data) => req({ url: '/manage/company.personnel_file/getCanAddFieldType',...data })
export const addEmployeeAttribute = (data) => req({ url: '/manage/company.personnel_file/addEmployeeAttribute',...data })
export const getEmployeeAttributeDetail = (data) => req({ url: '/manage/company.personnel_file/getEmployeeAttributeDetail',...data })
export const editEmployeeAttribute = (data) => req({ url: '/manage/company.personnel_file/editEmployeeAttribute',...data })
export const changeEmployeeAttributeStatus = (data) => req({ url: '/manage/company.personnel_file/changeEmployeeAttributeStatus',...data })
export const changeEmployeeAttributeField = (data) => req({ url: '/manage/company.personnel_file/changeEmployeeAttributeField',...data })
export const getNoneEmployeeAttributeField = (data) => req({ url: '/manage/company.personnel_file/getNoneEmployeeAttributeField',...data })
export const changeMulEmployeeAttributeField = (data) => req({ url: '/manage/company.personnel_file/changeMulEmployeeAttributeField',...data })

//员工档案材料
export const getEmployeeProfileInfo = (data) => req({ url: '/manage/company.personnel_file/getEmployeeProfileInfo',...data })
export const getEmployeeProfileSelect = (data) => req({ url: '/manage/company.personnel_file/getEmployeeProfileSelect',...data })
export const saveEmployeeProfile = (data) => req({ url: '/manage/company.personnel_file/saveEmployeeProfile',...data })
export const editEmployeeProfile = (data) => req({ url: '/manage/company.personnel_file/editEmployeeProfile',...data })
export const sortEmployeeProfile = (data) => req({ url: '/manage/company.personnel_file/sortEmployeeProfile',...data })
export const delEmployeeProfile = (data) => req({ url: '/manage/company.personnel_file/delEmployeeProfile',...data })
export const getEmployeeProfileCateInfo = (data) => req({ url: '/manage/company.personnel_file/getEmployeeProfileCateInfo',...data })

//自定义设置.
export const getCustomSettingInfo = (data) => req({ url: '/manage/company.personnel_file/getCustomSettingInfo',...data })
export const getCustomSettingDetail = (data) => req({ url: '/manage/company.personnel_file/getCustomSettingDetail',...data })
export const changeCustomSettingStatic = (data) => req({ url: '/manage/company.personnel_file/changeCustomSettingStatic',...data })
export const changeCustomSettingStatus = (data) => req({ url: '/manage/company.personnel_file/changeCustomSettingStatus',...data })
export const sortCustomSetting = (data) => req({ url: '/manage/company.personnel_file/sortCustomSetting',...data })
export const addCustomSettingCate = (data) => req({ url: '/manage/company.personnel_file/addCustomSettingCate',...data })
export const editCustomSettingCate = (data) => req({ url: '/manage/company.personnel_file/editCustomSettingCate',...data })


export const delCustomSettingCate = (data) => req({ url: '/manage/company.personnel_file/delCustomSettingCate',...data })
export const getCustomSettingCateSelect = (data) => req({ url: '/manage/company.personnel_file/getCustomSettingCateSelect',...data })
export const addCustomSetting = (data) => req({ url: '/manage/company.personnel_file/addCustomSetting',...data })


export const editCustomSetting = (data) => req({ url: '/manage/company.personnel_file/editCustomSetting',...data })
export const delCustomSetting = (data) => req({ url: '/manage/company.personnel_file/delCustomSetting',...data })
export const mergeCustomSetting = (data) => req({ url: '/manage/company.personnel_file/mergeCustomSetting',...data })

// 特殊人员 
// export const getConcealUserList = (data) => req({ url: '/manage/company.special_user/getConcealUserList',...data })

export const getConcealUserList = function (data){
    return method.get_list({
        url:'/manage/company.special_user/getConcealUserList',
        ...data
    })
}

// export const getBlacklistUserList = (data) => req({ url: '/manage/company.special_user/getBlacklistUserList',...data })
export const getBlacklistUserList = function (data){
    return method.get_list({
        url:'/manage/company.special_user/getBlacklistUserList',
        ...data
    })
}


export const cancleConcealUser = (data) => req({ url: '/manage/company.special_user/cancleConcealUser',...data })
export const addConcealUser = (data) => req({ url: '/manage/company.special_user/addConcealUser',...data })
export const addBlacklistUser = (data) => req({ url: '/manage/company.special_user/addBlacklistUser',...data })
export const exportConcealUser = (data) => req({ url: '/manage/company.special_user/exportConcealUser',...data })


export const editBlacklistUser = (data) => req({ url: '/manage/company.special_user/editBlacklistUser',...data })
export const cancleBlacklistUser = (data) => req({ url: '/manage/company.special_user/cancleBlacklistUser',...data })
export const batchCancleBlacklistUser = (data) => req({ url: '/manage/company.special_user/batchCancleBlacklistUser',...data })

export const importBlacklistUser = (data) => req({ url: '/manage/company.special_user/importBlacklistUser',...data })
export const exportBlacklistUserTemplate = (data) => req({ url: '/manage/company.special_user/exportBlacklistUserTemplate',...data })

export const exportBlacklistUser = (data) => req({ url: '/manage/company.special_user/exportBlacklistUser',...data })

// 人事规则/manage/company.personnel_rules/getPersonnelRulesInfo
export const getPersonnelRulesInfo = (data) => req({ url: '/manage/company.personnel_rules/getPersonnelRulesInfo',...data })
export const savePersonnelRules = (data) => req({ url: '/manage/company.personnel_rules/savePersonnelRules',...data })
export const getPersonnelUserRule = (data) => req({ url: '/manage/company.personnel_rules/getPersonnelUserRule',...data })
export const changePersonnelUserRule = (data) => req({ url: '/manage/company.personnel_rules/changePersonnelUserRule',...data })

// export const getNoneCodeUserList = (data) => req({ url: '/manage/company.personnel_rules/getNoneCodeUserList',...data })



export const getNoneCodeUserList = function (data){
    return method.get_list({
        url:'/manage/company.personnel_rules/getNoneCodeUserList',
        ...data
    })
}

export const saveUserCode = (data) => req({ url: '/manage/company.personnel_rules/saveUserCode',...data })



export const batchSaveUserCode = (data) => req({ url: '/manage/company.personnel_rules/batchSaveUserCode',...data })

export const getBatchNoneCodeUser = (data) => req({ url: '/manage/company.personnel_rules/getBatchNoneCodeUser',...data })


export const getPartList = (data) => req({ url: '/manage/company.part/getPartList',...data })
export const addPart = (data) => req({ url: '/manage/company.part/addPart',...data })
export const editPart = (data) => req({ url: '/manage/company.part/editPart',...data })
export const delPart = (data) => req({ url: '/manage/company.part/delPart',...data })
export const dividePart = (data) => req({ url: '/manage/company.part/dividePart',...data })
export const getPartUserList = (data) => req({ url: '/manage/company.part/getPartUserList',...data })

export const editDivide = (data) => req({ url: '/manage/company.part/editDivide',...data })
export const cancleDivide = (data) => req({ url: '/manage/company.part/cancleDivide',...data })
export const getOperateRecord = (data) => req({ url: '/manage/company.part/getOperateRecord',...data })


// 人事表格

export const getPersonnelForm = (data) => req({ url: '/manage/company.personnel_form/getPersonnelForm',...data })
export const savePersonnelForm = (data) => req({ url: '/manage/company.personnel_form/savePersonnelForm',...data })

export const getDownloadAddress = (data) => req({ url: '/manage/company.index/getDownloadAddress',...data })
// export const savePersonnelForm = (data) => req({ url: '/manage/company.personnel_form/savePersonnelForm',...data })

export const getHeaderSelect = (data) => req({ url: '/manage/company.personnel_form/getHeaderSelect',...data })

export const saveHeaderStyle = (data) => req({ url: '/manage/company.personnel_form/saveHeaderStyle',...data })
export const getHeaderDetail = (data) => req({ url: '/manage/company.personnel_form/getHeaderDetail',...data })

// q权限组


export const getPermissionAuthList = (data) => req({ url: '/manage/company.account_permission/getPermissionAuthList',...data })
export const getPermissionAuthDetail = (data) => req({ url: '/manage/company.account_permission/getPermissionAuthDetail',...data })
export const editPermissionAuth = (data) => req({ url: '/manage/company.account_permission/editPermissionAuth',...data })
export const addPermissionAuth = (data) => req({ url: '/manage/company.account_permission/addPermissionAuth',...data })
export const delPermissionAuth = (data) => req({ url: '/manage/company.account_permission/delPermissionAuth',...data })
export const getPermissionManageList = (data) => req({ url: '/manage/company.account_permission/getPermissionManageList',...data })
export const addPermissionManage = (data) => req({ url: '/manage/company.account_permission/addPermissionManage',...data })
export const getPermissionManageDetail = (data) => req({ url: '/manage/company.account_permission/getPermissionManageDetail',...data })
export const editPermissionManage = (data) => req({ url: '/manage/company.account_permission/editPermissionManage',...data })
export const delPermissionManage = (data) => req({ url: '/manage/company.account_permission/delPermissionManage',...data })

// 权限
export const getSuperManageList = (data) => req({ url: '/manage/company.account_permission/getSuperManageList',...data })
export const getGradeManageList = (data) => req({ url: '/manage/company.account_permission/getGradeManageList',...data })
export const getManageUserSelect = (data) => req({ url: '/manage/company.account_permission/getManageUserSelect',...data })
export const transferCreator = (data) => req({ url: '/manage/company.account_permission/transferCreator',...data })
export const editManageRemark = (data) => req({ url: '/manage/company.account_permission/editManageRemark',...data })
export const addSuperManage = (data) => req({ url: '/manage/company.account_permission/addSuperManage',...data })
export const addGradeManage = (data) => req({ url: '/manage/company.account_permission/addGradeManage',...data })

export const changeManageStatus = (data) => req({ url: '/manage/company.account_permission/changeManageStatus',...data })
export const cancleManageUser = (data) => req({ url: '/manage/company.account_permission/cancleManageUser',...data })
export const getAuthSelect = (data) => req({ url: '/manage/company.account_permission/getAuthSelect',...data })
export const getAuthList = (data) => req({ url: '/manage/company.account_permission/getAuthList',...data })

export const getGradeManageDetail = (data) => req({ url: '/manage/company.account_permission/getGradeManageDetail',...data })
export const editGradeManage = (data) => req({ url: '/manage/company.account_permission/editGradeManage',...data })

export const batchManageCancle = (data) => req({ url: '/manage/company.account_permission/batchManageCancle',...data })

export const batchManageForbid = (data) => req({ url: '/manage/company.account_permission/batchManageForbid',...data })
export const batchManageStart = (data) => req({ url: '/manage/company.account_permission/batchManageStart',...data })

export const getBatchUser = (data) => req({ url: '/manage/company.account_permission/getBatchUser',...data })
export const batchChangeMangeAuth = (data) => req({ url: '/manage/company.account_permission/batchChangeMangeAuth',...data })

export const getManageStatusSelect = (data) => req({ url: '/manage/company.account_permission/getManageStatusSelect',...data })

export const addCodeRule = (data) => req({ url: '/manage/company.personnel_rules/addCodeRule',...data })
export const getCodeRuleDetail = (data) => req({ url: '/manage/company.personnel_rules/getCodeRuleDetail',...data })

export const delCodeRule = (data) => req({ url: '/manage/company.personnel_rules/delCodeRule',...data })
export const editCodeRule = (data) => req({ url: '/manage/company.personnel_rules/editCodeRule',...data })

